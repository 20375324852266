import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ApiService } from '@app/core/services/api.service';
import { CurrentUserService } from '@app/core/services/current-user.service';
import { NotificationForUser } from '@app/core/types/notification';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { User } from '@app/core/types/user';
import moment from 'moment';

let instanceCounter = 0;

@Injectable()
export class NotificationService {
  notificationsSubject = new BehaviorSubject<NotificationForUser[]>([]);
  newNotificationsSubject = new BehaviorSubject<NotificationForUser[]>([]);
  pollingInterval: NodeJS.Timer | null = null;
  _unsubscribeAll: Subject<void> = new Subject<void>();
  instanceId = ++instanceCounter;

  constructor(
    private currentUserService: CurrentUserService,
    private apiService: ApiService,
    private authService: AuthenticationService,
  ) {
    this.fetchNotifications();
    this.startPolling();
  }

  startPolling(): void {
    this.pollingInterval = setInterval(() => {
      if (this.currentUserService.getUser()) {
        this.fetchNotifications();
      }
    }, 30000);
  }

  get notifications(): Observable<NotificationForUser[]> {
    return this.notificationsSubject.asObservable();
  }

  get newNotifications(): Observable<NotificationForUser[]> {
    return this.newNotificationsSubject.asObservable();
  }

  fetchNotifications(): void {
    //-- objects from BE are always in utc time
    const latestUpdateUtc = this.notificationsSubject.value[0]?.publishTime ?? null;
    this.apiService.getNotificationsForUser(latestUpdateUtc).subscribe((notificationResults) => {
      const currentNotifications = this.notificationsSubject.value;
      //-- notifications that are new to the list are "new"
      const newNotifications = notificationResults.notifications.filter(
        (notification) =>
          !currentNotifications.some(
            (existingNotification) => existingNotification._id === notification._id,
          ),
      );
      this.newNotificationsSubject.next(newNotifications);
      currentNotifications.unshift(...newNotifications);
      this.notificationsSubject.next(currentNotifications);
    });
  }

  markAsRead(notificationIds: string[]): void {
    this.apiService.markNotificationsAsSeen(notificationIds).subscribe((seenAt) => {
      //-- update notifications we currently have to show correct seenAt
      const notifications = this.notificationsSubject.value.map((notification) =>
        notificationIds.includes(notification._id)
          ? { ...notification, seenAt: moment.utc(seenAt).toDate() }
          : notification,
      );
      this.notificationsSubject.next(notifications);
      this.newNotificationsSubject.next(
        this.newNotificationsSubject.value.filter((n) => !notificationIds.includes(n._id)),
      );
    });
  }
}
