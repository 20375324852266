// @ts-strict-ignore
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, skip, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { navigation } from 'app/navigation/navigation';
import { User } from '@app/core/types/user';
import { MatDialog } from '@angular/material/dialog';
import { WelcomeDialogComponent } from '@app/shared/components/welcome-dialog/welcome-dialog.component';
import {
  ApiService,
  AuthenticationService,
  CurrentUserService,
  UnsavedChangesService,
} from '@app/core/services';
import { NotificationService } from '@app/core/services/notification.service';
import { NotificationForUser, NotificationSource } from '@app/core/types/notification';
import { NotificationDialogComponent } from '@app/shared/components/notification-dialog/notification-dialog.component';

type MenuNotification = NotificationForUser;

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  providers: [NotificationService],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  navigation: any;
  userStatusOptions: any[];
  user: User;
  isLoggedIn$: Observable<boolean>;

  notifications: MenuNotification[] = [];
  newNotifications: MenuNotification[] = [];
  newNotificationsNumber = null;
  popupNotifications: MenuNotification[] = [];
  openPopupDialogs: Set<string> = new Set();

  loginMessageSeenTime: Date;
  firstMessageCheck = true;

  // Private
  private _unsubscribeAll: Subject<void>;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _matDialog: MatDialog,
    private authenticationService: AuthenticationService,
    private currentUserService: CurrentUserService,
    private notificationService: NotificationService,
    private apiService: ApiService,
    private router: Router,
    private unsavedChangesService: UnsavedChangesService,
    private cdr: ChangeDetectorRef,
  ) {
    // Set the defaults
    this.userStatusOptions = [
      {
        title: 'Online',
        icon: 'icon-checkbox-marked-circle',
        color: '#4CAF50',
      },
      {
        title: 'Away',
        icon: 'icon-clock',
        color: '#FFC107',
      },
      {
        title: 'Do not Disturb',
        icon: 'icon-minus-circle',
        color: '#F44336',
      },
      {
        title: 'Invisible',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#BDBDBD',
      },
      {
        title: 'Offline',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#616161',
      },
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.isLoggedIn$ = this.currentUserService.isLoggedIn;
    this.getUserData();

    //-- subscribe to notification changes - do we need both?
    this.notificationService.notifications
      .pipe(takeUntil(this._unsubscribeAll), skip(1))
      .subscribe((notifications) => this.notificationsUpdated(notifications));
    this.notificationService.newNotifications
      .pipe(takeUntil(this._unsubscribeAll), skip(1))
      .subscribe((notifications) => this.newNotificationsUpdated(notifications));

    // Subscribe to the config changes
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((settings) => {
      this.horizontalNavbar = settings.layout.navbar.position === 'top';
      this.rightNavbar = settings.layout.navbar.position === 'right';
      this.hiddenNavbar = settings.layout.navbar.hidden === true;
    });

    const user = this.currentUserService.getUser();
    if (!user.root && this.currentUserService.getFirstLogin()) {
      let maxHeight = '50em';
      let width = '70%';
      if (user.client) maxHeight = '30em';
      // '< 959' = lt-md
      if (window.innerWidth < 959) {
        maxHeight = '75vh';
        width = '95%';
      }

      this._matDialog
        .open(WelcomeDialogComponent, {
          height: '90%',
          width: width,
          maxHeight: maxHeight,
        })
        .afterClosed()
        .subscribe(
          () => {
            this.currentUserService.setFirstLogin(false);
          },
          (err) => {
            console.error(err);
          },
        );
    }
  }

  notificationsUpdated(notifications: NotificationForUser[]): void {
    this.notifications = [...notifications.filter((notification) => notification.menuNotification)];
    this.popupNotifications = this.notifications.filter(
      (notification) => notification.popup && !notification.seenAt,
    );

    for (const notification of this.notifications) {
      notification.showContentRows = notification.content.split('\n').slice(0, 3);
    }
    if (this.popupNotifications.length > 0) {
      this.popupNotifications.forEach((notification) => {
        this.openNotificationDialog(notification);
      });
    }
  }

  newNotificationsUpdated(notifications: NotificationForUser[]): void {
    if (this.firstMessageCheck === false && notifications.length > 0) {
      void this.playPing();
    }
    this.newNotifications = notifications.filter((notification) => notification.menuNotification);
    if (this.firstMessageCheck) {
      //-- on first load only mark unseen notifications as new, later on all that come as "new" are new
      this.newNotifications = this.newNotifications.filter((notification) => {
        !!notification.seenAt;
      });
    }
    this.newNotificationsNumber = this.newNotifications.length;
    this.firstMessageCheck = false;
  }

  openNotificationDialog(notification: NotificationForUser): void {
    // Check if the dialog for this notification is already open
    if (!this.openPopupDialogs.has(notification._id)) {
      this.openPopupDialogs.add(notification._id); // Mark this notification as open
      this._matDialog
        .open(NotificationDialogComponent, {
          data: notification,
        })
        .afterClosed()
        .subscribe(() => {
          this.openPopupDialogs.delete(notification._id); // Remove from open dialogs set
          this.updateNotificationSeen(notification);
        });
    }
  }

  getUserData(): void {
    this.currentUserService
      .currentUser()
      .pipe(
        filter((value) => value !== null && value._id !== undefined),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe((user: User) => {
        this.user = user;
        if (!this.loginMessageSeenTime) {
          this.loginMessageSeenTime = user.newMessagesSeenTime;
        }
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  signOut(): void {
    this.router.navigate(['/auth/logout']);
  }

  //-- TODO: figure out what we do with other types of notifications (maybe open it in a popup dialog?)
  //-- For now we just mimic the existing menu and everything goes to the offer/order in question
  redirectToQuotation(notification: NotificationForUser): void {
    /** This is required for route reloading **/
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    //-- The fragment makes the view scroll to the chat
    const fragment =
      notification.source === NotificationSource.CHAT
        ? {
            fragment: 'chat',
          }
        : {};
    if (notification.relatedTo.order) {
      this.router.navigate(['./list/order/' + notification.relatedTo.order], fragment);
    } else if (notification.relatedTo.quotation) {
      this.router.navigate(['./list/offer/' + notification.relatedTo.quotation], fragment);
    } else if (notification.source === NotificationSource.ANNOUNCEMENT) {
      this.openNotificationDialog(notification);
    }
  }

  updateNotificationSeen(notification: MenuNotification): void {
    this.notificationService.markAsRead([notification._id]);
  }

  updateMenuNotificationsSeen() {
    if (this.notifications.length > 0) {
      setTimeout(() => {
        //-- only mark menu notifications seen here, popups are marked when the dialog is closed
        this.notificationService.markAsRead(
          this.notifications
            .filter((notification) => notification.menuNotification)
            .map((notification) => notification._id),
        );
      }, 5000);
    }
  }

  async playPing(): Promise<void> {
    try {
      const audio = new Audio();
      audio.src = '../../../../assets/sounds/ping.mp3';
      audio.load();
      await audio.play();
    } catch (err) {
      if (err.name !== 'NotAllowedError') {
        // If error is "NotAllowedError", the browser has been blocked the sound. No need log that error.
        console.error('Failed to play ping sound', err);
      }
    }
  }
}
