import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { MarkdownModule } from 'ngx-markdown';

import { AppComponent } from 'app/app.component';
import { CoreModule } from 'app/core/core.module';
import { environment } from 'environments/environment';

import { AuthGuard, LoginGuard } from '@app/core/guards';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';

const appRoutes: Routes = [
  {
    path: 'external',
    loadChildren: () =>
      import('./main/external-pages/external-pages.module').then((m) => m.ExternalPagesModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./main/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'airplane-pool',
    loadChildren: () =>
      import('./main/airplane-pool/airplane-pool.module').then((m) => m.AirplanePoolModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./main/authentication/authentication.module').then((m) => m.AuthenticationModule),
    resolve: [LoginGuard],
  },
  {
    path: 'faq',
    loadChildren: () => import('./main/faq/faq.module').then((m) => m.FaqModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then((m) => m.PagesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'message-center',
    loadChildren: () => import('./main/chat/chat.module').then((m) => m.ChatModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'account',
    loadChildren: () => import('./main/account/account.module').then((m) => m.AccountModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'enquiry',
    loadChildren: () => import('./main/enquiry/enquiry.module').then((m) => m.EnquiryModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'list',
    loadChildren: () => import('./main/quotation/quotation.module').then((m) => m.QuotationModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'airline',
    loadChildren: () => import('./main/airline/airline.module').then((m) => m.AirlineModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'travel-agency',
    loadChildren: () =>
      import('./main/travel-agency/travel-agency.module').then((m) => m.TravelAgencyModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./main/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'reports',
    loadChildren: () => import('./main/reports/reports.module').then((m) => m.ReportsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'management/users',
    loadChildren: () => import('./main/management/user/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'commissions',
    loadChildren: () =>
      import('./main/commissions/commissions.module').then((m) => m.CommissionsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./main/notifications/notifications.module').then((m) => m.NotificationsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'quotation/',
    redirectTo: '/list/offer/',
  },
  {
    path: 'quotation/:referenceNumber',
    redirectTo: '/list/offer/:referenceNumber',
  },
  {
    path: 'order/',
    redirectTo: '/list/order/',
  },
  {
    path: 'order/:referenceNumber',
    redirectTo: '/list/order/:referenceNumber',
  },
  {
    path: '',
    pathMatch: 'prefix', //default
    redirectTo: '/dashboard',
  },
  {
    path: '**',
    redirectTo: '/pages/coming-soon',
  },
];

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 1500,
  disableTooltipInteractivity: true,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload',
    }),
    MarkdownModule.forRoot(),
    CoreModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot(environment.production ? 'G-30KPYEDR62' : 'G-JRFNS2MMSJ'),
    NgxGoogleAnalyticsRouterModule,
  ],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'always',
      },
    },
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
