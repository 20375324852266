import { FormControls } from '@app/core/types/angular-forms';
import { User } from '@app/core/types/user';
import { ContractFile, Quotation, QuotationStatus } from '@app/core/types/quotation';

export const getAvailableAttachmentTypes = (quotation: Quotation, user: User): AttachmentType[] => {
  const attachmentTypes = [];
  if (user.root || user.airline) {
    attachmentTypes.push(AttachmentType.ATTACHMENT);
    attachmentTypes.push(AttachmentType.SEAT_MAP);
    if (
      [
        QuotationStatus.OFFER_CONTRACT_REQUESTED,
        QuotationStatus.OFFER_CONTRACT_SENT,
        QuotationStatus.OFFER_CLIENT_CONFIRMED,
      ].includes(quotation.status) ||
      quotation.confirmedByAirline
    ) {
      attachmentTypes.push(AttachmentType.CONTRACT);
    }
    if (quotation.confirmedByAirline) {
      attachmentTypes.push(AttachmentType.INVOICE);
    }
  }
  if (user.root || user.client) {
    if (quotation.confirmedByAirline) attachmentTypes.push(AttachmentType.PAX_LIST);
  }
  if (user.root) {
    attachmentTypes.push(AttachmentType.ADMIN_FILE_TO_AIRLINE);
    attachmentTypes.push(AttachmentType.ADMIN_FILE_TO_CLIENT);
  }
  return attachmentTypes;
};

export enum AttachmentType {
  ATTACHMENT = 'attachment',
  CONTRACT = 'contract',
  INVOICE = 'invoice',
  MESSAGE = 'message',
  PAX_LIST = 'pax-list',
  EXTERNAL_URL = 'external-url',
  PAX_LIST_TEMPLATE = 'pax-list-template',
  SEAT_MAP = 'seat-map',
  ADMIN_FILE_TO_AIRLINE = 'admin-file-to-airline',
  ADMIN_FILE_TO_CLIENT = 'admin-file-to-client',
}

export type Attachment = {
  originalFilename?: string;
  cloudFilename?: string;
  cloudPath?: string;
  uploadedAt?: Date;
  attachmentType: AttachmentType;
};

export type MessageAttachment = {
  messageId: string;
  attachment: Attachment;
  client?: string;
  airline?: string;
};

export type AttachmentsList = {
  contractTerms: Attachment[];
  attachments: Attachment[];
  termsAndConditions: ContractFile[];
  messageAttachments: MessageAttachment[];
  paxLists: Attachment[];
  invoices: Attachment[];
};

export type AttachmentForm = FormControls<{ attachments: Attachment[] }>;
