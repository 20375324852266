import { NgModule } from '@angular/core';

import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';

import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
/* Helpers */
import { PruningTranslationLoader } from '@app/shared/helpers/pruning-translation-loader';

/* Material modules */
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule as MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatGridListModule } from '@angular/material/grid-list';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

/* App Shared components */
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { WelcomeDialogComponent } from './components/welcome-dialog/welcome-dialog.component';
import { PendingResponseListComponent } from './components/pending-response-list/pending-response-list.component';
import { ChartlinesComponent } from './components/chartlines/chartlines.component';
import { MyAircraftsComponent } from './components/myaircrafts/myaircrafts.component';
import { ChatMessagesComponent } from '@app/shared/components/chat-messages/chat-messages.component';
import { EditMessageDialogComponent } from '@app/shared/components/edit-message-dialog/edit-message-dialog.component';

/*Services Shared*/
import { AirplanePoolService } from '@app/main/airplane-pool/airplane-pool.service';

/* Third party modules */
import { NgApexchartsModule } from 'ng-apexcharts';
import { SelectPriceLastQuotation } from './pipes/select-price-last-quotation';
import { DurationTime } from './pipes/durationTime';

import { FormatDate } from './pipes/formatDate';
import { FormatTime } from './pipes/formatTime';
import { FormatDatetime } from './pipes/formatDatetime';
import { FormatPercent } from './pipes/formatPercent';
import { KmsToMiles } from './pipes/kmsToMiles';
import { PricePerMile } from './pipes/pricePerMile';
import { SplitNameBySpace } from './pipes/split-name-by-space.pipe';
import { UtilsHelper } from './helpers/utils';

import { MccTimerPickerModule } from 'material-community-components/timer-picker';

//import { GanttChartComponent } from './components/availability-chart/availability-chart.component';
import { MatSidenavModule } from '@angular/material/sidenav';

import { AvatarModule } from 'ngx-avatars';

import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FileUploadModule } from 'ng2-file-upload';
import { FilePreviewModule } from './components/file-preview/file-preview.module';
import { VersionComponent } from './components/version/version.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ActionLogDialogComponent } from '@app/shared/components/action-log-dialog/action-log-dialog.component';
import { NotesDialogComponent } from './components/notes-dialog/notes-dialog.component';
import { PageHeaderComponent } from '@app/shared/components/page-header/page-header.component';
import { PlaneMapComponent } from './components/plane-map/plane-map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { PlaneMapInfoWindowComponent } from '@app/shared/components/plane-map/plane-map-info-window.component';
import { GoogleMapService } from '@app/core/services/googleMap.service';
import { InputAirportComponent } from './components/input-airport/input-airport.component';
import { InputFlightDateComponent } from './components/input-flight-date/input-flight-date.component';
import { InputCountryComponent } from './components/input-country/input-country.component';
import { BlurOverlayComponent } from './components/blur-overlay/blur-overlay.component';
import { InputAircraftComponent } from './components/input-aircraft/input-aircraft.component';
import { FeatureNotAvailableCardComponent } from '@app/feature-not-available-card/feature-not-available-card.component';
import { InputAirplaneComponent } from './components/input-airplane/input-airplane.component';
import { AutocompletePositionDirective } from '@app/shared/directives/autocomplete-position-directive.directive';
import { AddRequiredPlaceholderDirective } from '@app/shared/directives/add-required-placeholder.directive';
import { EstimatedTimeDialogComponent } from './components/estimated-time-dialog/estimated-time-dialog.component';
import { AttachmentListComponent } from './components/attachment-list/attachment-list.component';
import { RouteListComponent } from './components/route-list/route-list.component';
import { RouteDialogComponent } from './components/route-dialog/route-dialog.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { InputPreviousValueHintComponent } from '@app/shared/components/input-previous-value-hint/input-previous-value-hint.component';
import { ShowPreviousValueDirective } from './components/input-previous-value-hint/show-previous-value.directive';
import { FormatCurrencyPipe } from '@app/shared/pipes/formatCurrency';
import { InputCurrencyComponent } from '@app/shared/components/input-currency/input-currency.component';
import { FormatDateUtc } from '@app/shared/pipes/formatDateUtc';
import { FormatDatetimeUtc } from '@app/shared/pipes/formatDatetimeUtc';
import { FormatTimeUtc } from '@app/shared/pipes/formatTimeUtc';
import { QuotationDetailsStepperComponent } from '@app/shared/components/quotation-details-stepper/quotation-details-stepper.component';
import { UploadAirlineAttachmentDialogComponent } from './components/upload-airline-attachment-dialog/upload-airline-attachment-dialog.component';
import { UploadAirlinePaxTemplateDialogComponent } from './components/upload-airline-pax-template-dialog/upload-airline-pax-template-dialog.component';
import { OperatingAirportsComponent } from './components/operating-airports/operating-airports.component';
import { InputClientComponent } from './components/input-client/input-client.component';
import { InputAirlineComponent } from './components/input-airline/input-airline.component';
import { ActionLogListComponent } from './components/action-log-list/action-log-list.component';
import { NotificationDialogComponent } from './components/notification-dialog/notification-dialog.component';
import { UserSelectDialogComponent } from './components/user-select-dialog/user-select-dialog.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new PruningTranslationLoader(http);
}

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    WelcomeDialogComponent,
    PendingResponseListComponent,
    ChartlinesComponent,
    SelectPriceLastQuotation,
    DurationTime,
    FormatDate,
    FormatDateUtc,
    FormatTime,
    FormatTimeUtc,
    FormatDatetime,
    FormatDatetimeUtc,
    FormatPercent,
    KmsToMiles,
    PricePerMile,
    SplitNameBySpace,
    MyAircraftsComponent,
    //GanttChartComponent,
    FileUploadComponent,
    VersionComponent,
    LoaderComponent,
    ActionLogDialogComponent,
    NotesDialogComponent,
    ChatMessagesComponent,
    EditMessageDialogComponent,
    PageHeaderComponent,
    PlaneMapComponent,
    PlaneMapInfoWindowComponent,
    InputAirportComponent,
    InputCountryComponent,
    InputFlightDateComponent,
    BlurOverlayComponent,
    InputAircraftComponent,
    FeatureNotAvailableCardComponent,
    InputAirplaneComponent,
    AutocompletePositionDirective,
    EstimatedTimeDialogComponent,
    AttachmentListComponent,
    RouteListComponent,
    RouteDialogComponent,
    PaginationComponent,
    InputPreviousValueHintComponent,
    ShowPreviousValueDirective,
    FormatCurrencyPipe,
    InputCurrencyComponent,
    QuotationDetailsStepperComponent,
    UploadAirlineAttachmentDialogComponent,
    AddRequiredPlaceholderDirective,
    UploadAirlinePaxTemplateDialogComponent,
    OperatingAirportsComponent,
    InputClientComponent,
    InputAirlineComponent,
    ActionLogListComponent,
    NotificationDialogComponent,
    UserSelectDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    DragDropModule,
    FlexLayoutModule,
    TranslateModule.forChild({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] },
      isolate: false,
    }),
    /* Material modules */
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatCheckboxModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatCardModule,
    MatStepperModule,
    MatRippleModule,
    MatBadgeModule,
    MatRadioModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatMomentDateModule,

    /* Third party modules */
    NgApexchartsModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MccTimerPickerModule,
    AvatarModule,
    FileUploadModule,
    FilePreviewModule,

    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,

    // Third party modules
  ],
  exports: [
    CommonModule,
    RouterModule,
    TranslateModule,

    /* Material Modules */
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatCardModule,
    MatStepperModule,
    MatToolbarModule,
    MatRippleModule,
    MatBadgeModule,
    MatRadioModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatDatepickerModule,
    DragDropModule,
    MatGridListModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,

    ConfirmDialogComponent,
    WelcomeDialogComponent,
    PendingResponseListComponent,
    ChartlinesComponent,
    SelectPriceLastQuotation,
    DurationTime,
    MyAircraftsComponent,
    MccTimerPickerModule,

    FormatDate,
    FormatDateUtc,
    FormatTime,
    FormatTimeUtc,
    FormatDatetime,
    FormatDatetimeUtc,
    FormatPercent,

    KmsToMiles,
    PricePerMile,
    SplitNameBySpace,
    //GanttChartComponent,
    AvatarModule,
    FileUploadComponent,
    VersionComponent,
    LoaderComponent,
    ActionLogDialogComponent,
    ChatMessagesComponent,
    EditMessageDialogComponent,
    PageHeaderComponent,
    InputAirportComponent,
    PlaneMapComponent,
    InputCountryComponent,
    InputFlightDateComponent,
    BlurOverlayComponent,
    InputAircraftComponent,
    InputAirplaneComponent,
    FeatureNotAvailableCardComponent,
    AttachmentListComponent,
    RouteListComponent,
    RouteDialogComponent,
    PaginationComponent,
    InputPreviousValueHintComponent,
    ShowPreviousValueDirective,
    FormatCurrencyPipe,
    InputCurrencyComponent,
    QuotationDetailsStepperComponent,
    AddRequiredPlaceholderDirective,
    OperatingAirportsComponent,
    InputClientComponent,
    InputAirlineComponent,
    ActionLogListComponent,
  ],
  providers: [
    AirplanePoolService,
    UtilsHelper,
    DatePipe,
    GoogleMapService,

    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
})
export class SharedModule {}
