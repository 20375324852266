import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationForUser } from '@app/core/types/notification';
import { User } from '@app/core/types/user';
import { CurrentUserService } from '@core-services';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
})
export class NotificationDialogComponent {
  user: User;
  notification: NotificationForUser;
  constructor(
    private dialogRef: MatDialogRef<NotificationDialogComponent>,
    private currentUserService: CurrentUserService,
    @Inject(MAT_DIALOG_DATA) public data: NotificationForUser,
  ) {
    this.user = currentUserService.getUser();
    this.notification = data;
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
