<mat-toolbar *ngIf="isLoggedIn$ | async" class="p-0 mat-elevation-z1" id="top-toolbar">
  <div *ngIf="user" fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <!-- TODO Fix: Img is not visible even if the sidebar is hidden -->
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button
        mat-icon-button
        class="navbar-toggle-button"
        *ngIf="!hiddenNavbar && !rightNavbar"
        (click)="toggleSidebarOpen('navbar')">
        <mat-icon class="burger-icon">menu</mat-icon>
      </button>
      <img
        class="logo-icon secondary-text"
        src="assets/images/logos/logo_for_white_or_light_colour_background.png" />

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
        <div class="logo ml-16">
          <img class="logo-icon" src="assets/images/logos/icon_transparent.png" />
        </div>
      </div>
    </div>

    <!-- Notification Menu Details -->
    <div *ngIf="user" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start end">
      <button
        mat-button
        [matMenuTriggerFor]="notificationMenu"
        class="user-button"
        data-test="notification-menu-open"
        (click)="updateMenuNotificationsSeen()">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-icon
            class="s-32"
            [class.no-new-messages]="newNotificationsNumber === 0"
            matBadgeColor="accent"
            matBadgePosition="above before"
            matBadge="{{ newNotificationsNumber }}"
            [matBadgeHidden]="newNotificationsNumber === 0"
            matBadgeOverlap="true">
            notifications
          </mat-icon>
        </div>
      </button>

      <mat-menu
        #notificationMenu="matMenu"
        [overlapTrigger]="false"
        yPosition="below"
        xPosition="after">
        <mat-list>
          <mat-list-item *ngIf="!notifications || notifications.length <= 0">
            <h3 matLine>{{ 'toolbar.noMessages' | translate }}</h3>
          </mat-list-item>

          <mat-list-item
            *ngFor="let notification of notifications; index as idx"
            (click)="redirectToQuotation(notification)"
            class="notification-list-item"
            data-test="notification-menu-item"
            [class.message-seen]="!!notification.seenAt"
            [class.message-new]="!notification.seenAt">
            <div matLine class="message-header">
              <span class="notification-title">{{ notification.title }}</span>
            </div>
            <div
              *ngFor="let contentRow of notification.showContentRows"
              matLine
              class="message-content">
              {{ contentRow }}
            </div>
            <div matLine class="message-timestamp">
              {{ notification.publishTime | formatDatetime }}
            </div>
          </mat-list-item>
        </mat-list>
      </mat-menu>
    </div>

    <!-- User Menu Details -->
    <div *ngIf="user" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center" class="mr-16">
      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button" data-test="user-menu">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img
            *ngIf="user.profileImageURL"
            class="avatar mr-0 mr-sm-16"
            [src]="user.profileImageURL" />
          <ngx-avatars
            size="40"
            *ngIf="!user.profileImageURL"
            class="avatar mr-0 mr-sm-16"
            [name]="user.fullName !== '' ? user.fullName : user.username"></ngx-avatars>
          <span class="username mr-12" fxHide fxShow.gt-sm>
            <span fxLayout="column" fxFlex fxLayoutAlign="space-around end">
              <div style="line-height: normal">{{ user.fullName || 'User' }}</div>
              <div class="companyName" *ngIf="user.airline">{{ user.airline.name }}</div>
              <div class="companyName" *ngIf="user.client">{{ user.client.name }}</div>
            </span>
          </span>

          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item [routerLink]="['/account/profile']" data-test="settings-button">
          <mat-icon>face</mat-icon>
          <span>{{ 'toolbar.userMenu.myProfile' | translate }}</span>
        </button>

        <button mat-menu-item [routerLink]="['/account/change-password']">
          <mat-icon>lock</mat-icon>
          <span>{{ 'toolbar.userMenu.changePassword' | translate }}</span>
        </button>

        <button mat-menu-item (click)="signOut()" data-test="logout-button">
          <mat-icon>exit_to_app</mat-icon>
          <span>{{ 'toolbar.userMenu.logout' | translate }}</span>
        </button>
      </mat-menu>

      <button
        mat-icon-button
        class="navbar-toggle-button"
        *ngIf="!hiddenNavbar && rightNavbar"
        (click)="toggleSidebarOpen('navbar')"
        fxHide.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
