<div
  fxLayout="column"
  fxflex="98"
  style="min-width: 98%"
  class="attachment-list-container"
  id="attachment-list">
  <div fxLayout="row">
    <mat-table
      [dataSource]="displayedAttachments"
      multiTemplateDataRows
      fxFlex="100"
      matSort
      (matSortChange)="changeSorting($event)"
      *ngIf="attachmentList.length > 0">
      <ng-container matColumnDef="originalFilename">
        <mat-header-cell
          *matHeaderCellDef
          fxLayoutAlign="center start"
          fxLayout="column"
          mat-sort-header>
          <mat-form-field class="typeSelect">
            <mat-select
              placeholder="{{ 'attachmentList.filter' | translate }}"
              (selectionChange)="changeFiltering($event)">
              <mat-option
                *ngFor="let attachmentType of getListTypes()"
                [value]="attachmentType"
                data-test="attachment-type-option">
                {{ 'attachmentList.type.' + attachmentType | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          data-test="attachment-name"
          fxLayout="column"
          style="padding-right: 10px"
          fxLayoutAlign="start start"
          (click)="openAttachment(row)">
          <p data-test="uploaded-file-list-row" title="{{ row.attachment.originalFilename }}">
            {{ row.attachment.originalFilename }}
          </p>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="attachmentType">
        <mat-header-cell
          fxFlex="25"
          *matHeaderCellDef
          fxLayoutAlign="center start"
          fxLayout="column"
          fxHide.xs
          mat-sort-header>
          {{ 'attachmentList.type' | translate }}
        </mat-header-cell>
        <mat-cell
          fxFlex="25"
          fxHide.xs
          fxLayout="column"
          *matCellDef="let row"
          data-test="attachment-type"
          fxLayoutAlign="start start"
          style="padding-right: 10px"
          (click)="openAttachment(row)">
          <div>{{ 'attachmentList.type.' + row.attachment.attachmentType | translate }}</div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="uploadedAt">
        <mat-header-cell
          *matHeaderCellDef
          fxFlex="20"
          fxFlex.xs="30"
          fxLayoutAlign="center start"
          fxLayout="column"
          mat-sort-header>
          {{ 'attachmentList.date' | translate }}
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          fxFlex="20"
          fxFlex.xs="30"
          fxLayoutAlign="center start"
          fxLayout="column">
          <div class="fileDate">{{ row.attachment.uploadedAt | formatDatetime }}</div>
          <div
            class="fileStatus"
            *ngIf="showSentStatus(row) && isAttachmentSentToClient(row) && !user.client">
            {{ 'attachmentList.status.sent' | translate }}
          </div>
          <div
            class="fileStatus"
            *ngIf="showSentStatus(row) && !isAttachmentSentToClient(row) && !user.client"
            [matTooltip]="getNotSentTooltip(row) | translate"
            matTooltipPosition="above">
            {{ 'attachmentList.status.not-sent' | translate }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell fxFlex="8" fxFlexAlign="end" *matHeaderCellDef></mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          fxLayoutAlign="center center"
          fxFlex="8"
          fxLayout="column"
          style="padding-right: 0">
          <button
            *ngIf="isAttachmentDeletable(row)"
            data-test="delete-attachment-button"
            type="button"
            style="overflow: show"
            mat-icon-button
            (click)="deleteAttachmentItem(row)">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="attachmentColumns"
        class="accent"
        style="min-height: 2.5em"></mat-header-row>
      <mat-row
        class="table-element-row"
        *matRowDef="let row; columns: attachmentColumns"
        style="min-height: 1.5em"></mat-row>
    </mat-table>
  </div>
  <pagination
    [showNoResults]="false"
    [pageSizeOptions]="[10]"
    [pageSize]="currentPagination.pageSize"
    [pageIndex]="currentPagination.pageIndex"
    [length]="attachmentList.length"
    (paginationChanged)="changePaging($event)"></pagination>
</div>
