import { NgModule, Optional, SkipSelf, ModuleWithProviders, APP_INITIALIZER } from '@angular/core';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LayoutModule } from 'app/layout/layout.module';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule } from '@fuse/components';
import { MatDialogModule } from '@angular/material/dialog';

import { fuseConfig } from 'app/fuse-config';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

/* Singleton services */
import {
  ToasterService,
  LocalStorageService,
  RecaptchaService,
  AppInitializerService,
  UnsavedChangesService,
} from '@app/core/services';

import { NgxCaptchaModule } from 'ngx-captcha';
/* Guards */
import { AuthGuard, LoginGuard, RoleGuard } from '@app/core/guards';

/* Interceptors */
import { AuthInterceptor, ErrorInterceptor } from '@app/core/interceptors';

/* Configs */
import { AppSettingsConfig } from '@app/core/configs/app-settings.config';

/* Helpers */
import { PruningTranslationLoader } from '@app/shared/helpers/pruning-translation-loader';

/* Material */
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { BreadcrumbModule } from 'xng-breadcrumb';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Observable } from 'rxjs';
import { User } from '@app/core/types/user';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

export function HttpLoaderFactory(http: HttpClient) {
  return new PruningTranslationLoader(http);
}

export function init_app(appLoadService: AppInitializerService): () => Observable<User | null> {
  return () => appLoadService.initializeApp();
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    BreadcrumbModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] },
      isolate: false,
    }),
    NgIdleKeepaliveModule.forRoot(),
    // Material
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,

    LayoutModule,
    NgxCaptchaModule,
  ],
  providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } }],
  declarations: [],
  exports: [
    BrowserAnimationsModule,
    BrowserModule,
    TranslateModule,
    BreadcrumbModule,
    // Material
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,

    /* Fuse Modules */
    FuseProgressBarModule,
    FuseSharedModule,

    LayoutModule,
    NgxCaptchaModule,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        /* Configs */
        AppSettingsConfig,
        /* Singleton services */
        AuthGuard,
        RoleGuard,
        LoginGuard,
        BreadcrumbService,
        ToasterService,
        LocalStorageService,
        AppInitializerService,
        RecaptchaService,
        UnsavedChangesService,
        {
          provide: APP_INITIALIZER,
          useFactory: init_app,
          deps: [AppInitializerService],
          multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      ],
    };
  }
}
