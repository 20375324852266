<div fxLayout="column" fusePerfectScrollbar>
  <mat-toolbar class="mat-toolbar mat-accent">
    <h2 mat-dialog-title>{{ notification.title }}</h2>
  </mat-toolbar>
  <mat-dialog-content>
    <p>
      <span style="white-space: pre-wrap" innerHtml="{{ notification.content }}"></span>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button color="accent" (click)="onClose()" fxAlignLayout="center">
      {{ 'OK' }}
    </button>
  </mat-dialog-actions>
</div>
