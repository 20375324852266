import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'menu',
    type: 'group',
    title: '',
    icon: 'apps',
    children: [
      {
        id: 'dashboards',
        title: 'Dashboard',
        translate: 'menu.dashboard',
        icon: 'dashboard',
        type: 'item',
        url: '/dashboard',
      },
      {
        id: 'client-admin',
        title: 'Client administratio',
        translate: 'menu.clientAdmin',
        icon: 'card_travel',
        type: 'item',
        url: '/admin/client-admin',
      },
      {
        id: 'AI',
        title: 'AI',
        translate: 'myPlane AI',
        icon: 'contact_support',
        type: 'item',
        url: '/admin/ai',
      },
      {
        id: 'airline-admin',
        title: 'Airline administration',
        translate: 'menu.airlineAdmin',
        icon: 'airplanemode_active',
        type: 'item',
        url: '/admin/airline-admin',
      },
      {
        id: 'enquiries_create',
        title: 'Book a plane',
        translate: 'menu.enquiries.main',
        icon: 'flight',
        type: 'item',
        url: '/enquiry/create',
      },
      {
        id: 'enquiries_list',
        title: 'Flight searches',
        translate: 'menu.enquiries.list',
        icon: 'search',
        type: 'item',
        url: '/list/enquiry/',
      },
      {
        id: 'offers',
        title: 'Offers',
        translate: 'menu.quotations.main',
        icon: 'flight_takeoff',
        type: 'item',
        url: '/list/offer/',
      },
      {
        id: 'orders',
        title: 'Orders',
        translate: 'menu.orders.main',
        icon: 'local_activity',
        type: 'item',
        url: '/list/order/',
      },
      {
        id: 'aircrafts',
        title: 'My Aircrafts',
        translate: 'menu.aircrafts',
        icon: 'flight',
        type: 'item',
        url: '/airplane-pool/my-aircrafts',
      },
      {
        id: 'airportGroups',
        title: 'AirportGroups',
        translate: 'menu.airportGroups',
        icon: 'map',
        type: 'item',
        url: '/airplane-pool/airport-groups',
      },
      {
        id: 'aircraft-type-management',
        title: 'Aircraft models',
        translate: 'menu.aircraft.models',
        icon: 'flight',
        type: 'item',
        url: '/admin/aircraft-type-management',
      },
      {
        id: 'crew',
        title: 'Crew',
        translate: 'menu.crew.main',
        icon: 'assignment_ind',
        type: 'item',
        url: '/pages/coming-soon/crew-configuration',
      },
      {
        id: 'catering',
        title: 'Catering',
        translate: 'menu.catering.main',
        icon: 'room_service',
        type: 'item',
        url: '/pages/coming-soon/catering-configuration',
      },
      {
        id: 'pooling',
        title: 'Pooling',
        translate: 'menu.pooling',
        icon: 'group_work',
        type: 'item',
        url: '/pages/coming-soon/pooling',
      },
      {
        id: 'acmi',
        title: 'ACMI',
        translate: 'menu.acmi.main',
        icon: 'compare_arrows',
        type: 'item',
        url: '/pages/coming-soon/acmi',
      },
      {
        id: 'help',
        title: 'help',
        translate: 'menu.help.main',
        icon: 'help',
        type: 'item',
        url: '/faq',
      },
    ],
  },
  {
    id: 'root-settings-section',
    title: 'Administration',
    translate: 'menu.administration',
    icon: 'settings',
    type: 'group',
    children: [
      {
        id: 'users_list',
        title: 'Users List',
        translate: 'menu.users.list',
        type: 'item',
        icon: 'people',
        url: '/management/users/main',
      },
      {
        id: 'commission-info',
        title: 'Commission Info',
        translate: 'menu.admin.commission',
        type: 'item',
        icon: 'settings',
        url: '/commissions/main',
      },
      {
        id: 'notifications',
        title: 'Notifications',
        translate: 'menu.admin.notifications',
        type: 'item',
        icon: 'notifications_active',
        url: '/notifications/main',
      },
    ],
  },
  {
    id: 'client-settings-section',
    title: 'Administration',
    translate: 'menu.administration',
    icon: 'settings',
    type: 'group',
    children: [
      {
        id: 'travel-agency-info',
        title: 'Booking Client Info',
        translate: 'menu.travelAgency.info',
        type: 'item',
        icon: 'settings',
        url: '/travel-agency/travel-agency-details',
      },
      {
        id: 'users_list',
        title: 'Users List',
        translate: 'menu.users.list',
        type: 'item',
        icon: 'people',
        url: '/management/users/main',
      },
    ],
  },
  {
    id: 'admin-reports',
    title: 'Reports',
    translate: 'menu.admin.reports',
    type: 'collapsable',
    icon: 'bar_chart',
    children: [
      {
        id: 'sales-report',
        title: 'Sales report',
        translate: 'menu.admin.salesReport',
        type: 'item',
        icon: 'bar_chart',
        url: '/reports/sales-report',
      },
      {
        id: 'request-report',
        title: 'Geographical',
        translate: 'menu.admin.geoReport',
        type: 'item',
        icon: 'bar_chart',
        url: '/reports/request-report',
      },
      {
        id: 'airline-report',
        title: 'Airline report',
        translate: 'menu.admin.airlineReport',
        type: 'item',
        icon: 'bar_chart',
        url: '/reports/airline-report',
      },
      {
        id: 'client-report',
        title: 'Client report',
        translate: 'menu.admin.clientReport',
        type: 'item',
        icon: 'bar_chart',
        url: '/reports/client-report',
      },
      /*
      {
        id: 'booking-client-report',
        title: 'Booking client report',
        translate: 'menu.admin.bookingClientReport',
        type: 'item',
        icon: 'bar_chart',
        url: '/reports/booking-client-report',
      },
      */
    ],
  },
  {
    id: 'airline-settings-section',
    title: 'Administration',
    translate: 'menu.administration',
    icon: 'people',
    type: 'group',
    children: [
      {
        id: 'edit-airline-info',
        title: 'Settings',
        translate: 'menu.myAirline.settings',
        type: 'item',
        icon: 'settings',
        url: '/airline/my-airline',
      },
      {
        id: 'users_list',
        title: 'Users List',
        translate: 'menu.users.list',
        type: 'item',
        icon: 'people',
        url: '/management/users/main',
      },
    ],
  },
];
